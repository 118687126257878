import React from 'react';
import { Box, Container } from '@mui/material';
import LayoutHeader from './LayoutHeader'; // Import the new LayoutHeader
import LayoutFooter from './LayoutFooter';

const Layout = ({ children }) => {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      
      {/* Header */}
      <LayoutHeader />

      {/* Main Content */}
      <Container component="main" sx={{ mt: 2, mb: 2, flex: 1 }}>
        {children}
      </Container>

      {/* Footer */}
      <LayoutFooter />
      
    </Box>
  );
};

export default Layout;
