import React from 'react';
import ProductForm from './ProductForm';
import { Paper, Typography } from '@mui/material';

const ProductSection = ({ title, onFormSubmit, sx }) => {
    return (
        <Paper sx={{
            p: 2,
            width: { xs: '100%', sm: '80%', md: '75%' },
            maxWidth: { xs: '100%', sm: '600px' },
            textAlign: 'center',
            my: 2,
            boxShadow: '5px 10px 30px rgba(0, 0, 0, 0.15)',
            border: 'none',
            ...sx 
        }}>
            <Typography
                variant="body1"
                sx={{
                height: 'auto', // Allow dynamic height
                fontFamily: 'Roboto',
                fontWeight: 400, // Regular
                fontSize: { xs: '1rem', sm: '1rem', md: '1rem' }, // Responsive font size
                lineHeight: 1.4,
                color: '#f5973a', // Adobe XD color
                textAlign: 'center',
                pt: '20px',
                pb: '10px',
                opacity: 1,
                zIndex: 10, // Ensure visibility above background
                }}
            >
                Find an Expert
            </Typography>
            <ProductForm productTitle={title} onFormSubmit={onFormSubmit} />
        </Paper>
    );
};

export default ProductSection;