
import React from 'react';
import { format, parse } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import RatingStars from './RatingStars';

const CoachCard = ({ coach, product, role, company }) => {

    const navigate = useNavigate(); 
    const location = useLocation();
    // const { targetRole, targetCompany } = location.state || {};
    // Function to format the date
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
    
        try {
            const date = new Date(dateString);
            // Ensure the date object is valid before formatting
            if(isNaN(date.getTime())) {
                throw new Error('NA');
            }
            const formattedDate = format(date, 'MMM do, EE'); 
            return formattedDate;
        } catch (error) {
            return 'NA'; 
        }
    };

    return (
        <Card sx={{
            width: { xs: '100%', sm: 300, md: 320 },
            height: { xs: 420, sm: 400, md: 460 },
            m: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            borderRadius: 4,
        }}>
            <CardContent sx={{ 
                flexGrow: 1,  
                display: 'flex',  // Ensures correct layout
                flexDirection: 'column',
                minHeight: 0  // Critical: Prevents pushing button down
            }}>
                {/* Central align only specific fields */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                    textAlign: 'center',
                }}>
                    <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1rem' }}>
                        {coach.unique_username}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#424242', mt: 1 }}>
                        {coach.present_role} at {coach.present_company}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {coach.sessions_completed} {coach.sessions_completed === 1 ? 'session' : 'sessions'} completed
                    </Typography>
                </Box>
                {/* DYNAMIC SCROLL AREA: Only Scrolls if Needed */}
                <Box sx={{ 
                    flexGrow: 1, 
                    minHeight: 0,
                    overflowY: 'auto',  
                    scrollbarWidth: 'thin',
                    "&::-webkit-scrollbar": { width: "4px" }, 
                }}>
                    {/* Align labels and values along the same x-axis */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', my: 0.4, alignItems: 'center' }}>
                            <Typography component="span" sx={{ width: '48%', fontWeight: 'bold' }}>Rating:</Typography>
                            <Box component="span" sx={{ width: '52%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                {coach.rating !== null ? (
                                    <RatingStars rating={coach.rating} />
                                ) : (
                                    <Typography color="text.secondary">NA</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', my: 0.4 }}>
                            <Typography component="span" sx={{ width: '48%', fontWeight: 'bold' }}>Experience:</Typography>
                            <Typography component="span" sx={{ width: '52%', textAlign: 'left' }}>{coach.past_companies.join(', ')}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', my: 0.4 }}>
                            <Typography component="span" sx={{ width: '48%', fontWeight: 'bold' }}>YoE:</Typography>
                            <Typography component="span" sx={{ width: '52%', textAlign: 'left' }}>{coach.yoe}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', my: 0.4 }}>
                            <Typography component="span" sx={{ width: '48%', fontWeight: 'bold' }}>Earliest Available:</Typography>
                            <Typography component="span" sx={{ width: '52%', textAlign: 'left' }}>{formatDate(coach.next_available_date)}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>

            <Box sx={{ m: 2 }}>
            <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                onClick={() => 
                    navigate(`/coaches/${coach.unique_username}?product=${product}&role=${role}&company=${company}`, { 
                        state: { id: coach.id, product, role, company } })}
                sx={{
                    backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)', // Set the background color to #1890ff
                    '&:hover': { backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)' },
                    color: '#04003F',
                    borderRadius: '4px',
                    height: '47px', // Increase the height here
                    mt: 1, 
                    mx: 'auto', 
                    width: 'calc(100% - 0px)',
                    textTransform: 'none',
                    fontSize: '1.0rem',
                }}
            >
                Book an hour for ${coach.price_per_session}
            </Button>
            </Box>
        </Card>
    );
};

const CoachList = ({ coaches, product, role, company }) => {
    if (coaches.length === 0) {
        return (
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                textAlign: 'center', 
                mt: {
                    xs: 15,  // Smaller margin-top for extra-small devices (mobile)
                    sm: 20,  // Slightly larger margin-top for small devices (tablets)
                    md: 25,  // For medium devices
                    lg: 30,  // Original margin-top for large devices (desktop)
                  }
              }}
            >
              No relevant coaches found.
            </Typography>
          );
    }
    const gridColumns = {
        xs: '1fr',  // One column on extra small devices
        sm: coaches.length === 1 ? '1fr' : 'repeat(2, 1fr)',  // Two columns on small devices unless there is only one coach
        md: coaches.length >= 3 ? 'repeat(3, 1fr)' : `repeat(${coaches.length}, 1fr)`  // Three columns on medium devices and up
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            padding: '20px',            
        }}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: gridColumns,
                gap: { xs: 2, sm: 4, md: 6 },
                justifyContent: 'center',
                // width: '100%',
                maxWidth: '1200px', // Ensure the grid does not exceed this width
            }}>
                {coaches.map((coach) => (
                    <CoachCard key={coach.unique_username} coach={coach} product={product} role={role} company={company} />
                ))}
            </Box>
        </Box>
    );
};

export default CoachList;