import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Container, TextField, Typography, Link,CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import getErrorMessage from '../utils/getErrorMessage';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { auth } from '../firebase';

const Register = () => {
  const [error, setError] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading spinner
  const navigate = useNavigate();
  const location = useLocation();
  const { handleGoogleAuth } = useGoogleAuth();
  const theme = useTheme();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/get_csrf_token/`, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setCsrfToken(data.csrfToken))
      .catch(error => setError('Error fetching CSRF token.'));
  }, []);

  const formik = useFormik({
    initialValues: { email: '', password: '', confirmPassword: '' },
    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
      setLoading(true); // Set loading to true when form is submitted
      try {
        const userCredential = await auth.createUserWithEmailAndPassword(values.email, values.password);
        const token = await userCredential.user.getIdToken();

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include',
          body: JSON.stringify({
            email: values.email,
            token: token,
          }),
        });

        if (!response.ok) {
          setLoading(false);
          if (response.status === 409) {
            setError('Email already exists. You can either sign-in or register a new account.');
          } else {
            const errorData = await response.json();
            setError(errorData.message || 'An unexpected error occurred. Try again.');
          }
          return;
        }

        // Determine where to redirect after Register
        const redirectTo = location.state?.from?.pathname || '/'; // Use stored location or default to home
        const redirectSearch = location.state?.from?.search || ''; // Preserve any query params

        navigate(`${redirectTo}${redirectSearch}`); // Redirect to the previous page
      } catch (firebaseError) {
        setLoading(false);
        setError(getErrorMessage(firebaseError));
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs" sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start',
      minHeight: '60vh',
      pt: { xs: 2, sm: 3, md: 4 },
      '& .MuiTextField-root': { m: 1, width: '100%', borderRadius: '4px' },
      '& .MuiButton-root': { m: 1, width: '100%', borderRadius: '4px', textTransform: 'none' },
      '@media (max-width:600px)': {
        '& .MuiButton-root': { height: '48px' },
        '& .MuiTextField-root': { height: '48px' },
      }
    }}>
      <Typography component="h1" variant="h5" sx={{
        fontWeight: 'bold',
        mb: 1,
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
      }}>
        Register
      </Typography>
      <Button
        variant="contained"
        onClick={handleGoogleAuth}
        sx={{
          mt: 1, mb: 2,
          backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)', 
          '&:hover': { backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)'},
          color: '#04003F',
          height: { xs: '48px', sm: '52px' },
          fontSize: '1.0rem'
        }}
      >
        Sign Up with Google
      </Button>
      <Typography variant="caption" sx={{
        my: 1,
        fontSize: '1.0rem',
        display: 'block'
      }}>
        OR
      </Typography>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ width: '100%', mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={formik.handleChange}
          value={formik.values.email}
          variant="outlined"
          sx={{ borderRadius: '4px', height: { xs: '48px', sm: '52px' } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={formik.handleChange}
          value={formik.values.password}
          variant="outlined"
          sx={{ borderRadius: '4px', height: { xs: '48px', sm: '52px' } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          id="confirmPassword"
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
          variant="outlined"
          sx={{ borderRadius: '4px', height: { xs: '48px', sm: '52px' } }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 2,
            backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)', 
            '&:hover': { backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)'},
            color: '#04003F',
            height: { xs: '48px', sm: '52px' },
            fontSize: '1.0rem'
          }}
          disabled={loading} // Disable button when loading
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'} 
        </Button>
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      </Box>
      <Link 
        component={RouterLink} 
        to="/signin" 
        state={{ from: location.state?.from }}
        variant="body2" 
        sx={{
          mt: 2,
          fontSize: { xs: '0.9rem', sm: '1.0rem' },
          textDecoration: 'underline'
      }}>
        Already have an account? Sign In
      </Link>
    </Container>
  );
};

export default Register;
