// AuthContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';

// Create a context for authentication state
const AuthContext = createContext();

// Hook for easy use of the context
export const useAuth = () => useContext(AuthContext);

// Provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to check session validity
  const checkSession = async (attempt = 1) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/details/`, {
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Session expired or cookies not ready');
      }
  
      const data = await response.json();
      setCurrentUser(data);
    } catch (error) {
      console.warn(`[Auth] Session check attempt ${attempt} failed: ${error.message}`);
  
      // Allow few retries, spaced out by 500ms
      if (attempt < 6) {
        setTimeout(() => checkSession(attempt + 1), 500);
      } else {
        console.error('[Auth] Session check failed after retries');
        setCurrentUser(null); // Only clear after we've retried
      }
    }
  };

  useEffect(() => {
    // Firebase authentication state change listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Check session on app load
    checkSession();

    // Detect when user returns to the tab
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkSession(); // Re-check session when user returns
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      unsubscribe(); // Unsubscribe from Firebase listener
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const value = {
    currentUser,
    setCurrentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children} {/* Only render children when not loading */}
    </AuthContext.Provider>
  );
};