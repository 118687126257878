import React from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// Import Footer Logo
import EastBadgeFooterLogo from '../assets/footer_assets/eastbadge-logo-white@2x.png';

const LayoutFooter = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Main Footer Section */}
      <Box
        component="footer"
        sx={{
          backgroundColor: '#04003F',
          color: 'white',
          p: { xs: 2, md: 4 }, // Increased padding for better spacing
          mt: 'auto',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            
            {/* Logo Section (Left - 30%) */}
            <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Box
                component="img"
                src={EastBadgeFooterLogo}
                alt="EastBadge Logo"
                sx={{
                  width: { xs: '120px', sm: '130px', md: '230px' }, // Adjust size for responsiveness
                  height: 'auto',
                  marginLeft: { xs: '0px', sm: '60px' },
                  mb: { xs: 2, sm: 0 }, // Margin for spacing
                }}
              />
            </Grid>

            {/* Product, Company & Legal Sections (Center - 40%) */}
            <Grid 
              item 
              xs={12} 
              sm={5} 
              sx={{ 
                display: { xs: 'grid', sm: 'grid', md: 'flex' }, 
                flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // Stack vertically on mobile, row on larger screens
                justifyContent: { xs: 'center', sm: 'center', md: 'space-between' },
                width: '100%', 
                alignItems: { xs: 'center', sm: 'center', md: 'flex-start' }, // Center on mobile, left-align on larger screens
                gap: { xs: 3, sm: 3, md: 0 }, 
              }}
            >
              <Box sx={{ flex: 1, maxWidth: '33.33%', display: 'flex', alignItems: 'flex-start', mb: { xs: 2, sm: 0 } }}> {/* Adds spacing when stacked */}
                <Box sx={{
                  width: '1px', 
                  backgroundColor: '#FECB10', 
                  flexShrink: 0,
                  alignSelf: 'stretch',
                  height: '100px', // Adjusted dynamically to match the text height
                  marginRight: '20px',
                  marginLeft: { xs: '0px', sm: '0px' }
                }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: { xs: 'left', sm: 'left' }, fontFamily: 'Roboto', fontSize: { xs: '0.9rem', sm: '0.9rem', md: '0.95rem' } }}>
                  <Typography 
                    variant="body1" 
                    sx={{ mb: 1, color: '#FECB10', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>
                    Products
                  </Typography>

                  {/* Ace with Mocks (Product Name) */}
                  <Typography sx={{ fontSize: '0.95rem', color: 'white', fontWeight: 'bold', mb: 0.5, whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>
                    Ace with Mocks
                  </Typography>

                  <Typography 
                    onClick={() => {
                      navigate("/coaches?product=Ace%20with%20Mocks&role=&company=");  
                    }} 
                    sx={{ 
                      textDecoration: 'none', 
                      color: 'white', 
                      fontSize: 'inherit', 
                      cursor: 'pointer', 
                      whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' },
                    }}
                  >
                    Browse Experts
                  </Typography>
                  <RouterLink to="/become-an-expert" style={{ textDecoration: 'none', color: 'white', fontSize: 'inherit', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>Become an Expert</RouterLink>
                </Box>
              </Box>

              {/* Company Section with Vertical Line */}
              <Box sx={{ flex: 1, maxWidth: '33.33%', display: 'flex', alignItems: 'flex-start', mb: { xs: 2, sm: 0 } }}> {/* Adds spacing when stacked */}
                <Box sx={{
                  width: '1px', 
                  backgroundColor: '#FECB10', 
                  flexShrink: 0,
                  alignSelf: 'stretch',
                  height: '100px', // Adjusted dynamically to match the text height
                  marginRight: '20px',
                  marginLeft: { xs: '0px', sm: '0px' }
                }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: { xs: 'left', sm: 'left' }, fontFamily: 'Roboto', fontSize: { xs: '0.9rem', sm: '0.9rem', md: '0.95rem' } }}>
                  <Typography 
                    variant="body1" 
                    sx={{ mb: 1, color: '#FECB10', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>
                    Company
                  </Typography>
                  <RouterLink to="/faqs" style={{ textDecoration: 'none', color: 'white', marginBottom: '8px', fontSize: 'inherit', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>FAQs</RouterLink>
                </Box>
              </Box>

              {/* Legal Section with Vertical Line */}
              <Box sx={{ flex: 1, maxWidth: '33.33%', display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{
                  width: '1px', 
                  backgroundColor: '#FECB10', 
                  flexShrink: 0,
                  alignSelf: 'stretch',
                  height: '100px', // Adjusted dynamically to match the text height
                  marginRight: '20px',
                }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: { xs: 'left', sm: 'left' }, fontFamily: 'Roboto', fontSize: { xs: '0.9rem', sm: '0.9rem', md: '0.95rem' } }}>
                  <Typography 
                    variant="body1" 
                    sx={{ mb: 1, color: '#FECB10', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>
                    Legal
                  </Typography>
                  <RouterLink to="/terms-of-use" style={{ textDecoration: 'none', color: 'white', marginBottom: '8px', fontSize: 'inherit', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>Terms of Use</RouterLink>
                  <RouterLink to="/privacy-policy" style={{ textDecoration: 'none', color: 'white', fontSize: 'inherit', whiteSpace: { xs: 'nowrap', sm: 'nowrap', md: 'normal' }, }}>Privacy Policy</RouterLink>
                </Box>
              </Box>

            </Grid>

            {/* Contact Info (Right - 30%) */}
            <Grid item xs={12} sm={2.4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', p: 2, ml: 2 }}>
                <Typography 
                  variant="body2" 
                  sx={{ fontFamily: 'Roboto', fontSize: { xs: '0.9rem', sm: '0.9rem', md: '.95rem' } }}>
                    For questions or feedback, contact 
                    us at <span style={{ color: '#FECB10' }}>support@eastbadge.com</span>
                </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

      {/* Copyright Box - Separate Below Footer */}
      <Box
        sx={{
          backgroundColor: 'white',
          color: '#04003F',
          fontFamily: 'Roboto',
          fontSize: { xs: '0.9rem', sm: '0.9rem', md: '0.95rem' }, // Responsive font size
          textAlign: 'center',
          p: 1.4, // Padding for a thinner height
        }}
      >
        © 2025 EastBadge, Inc. All rights reserved.
      </Box>
    </>
  );
};

export default LayoutFooter;
