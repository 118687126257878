import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CoachList from '../components/CoachList';
import NotFoundPage from './NotFoundPage';
import ErrorMessagePage from './ErrorMessagePage';
import { VALID_PRODUCTS } from '../constants'; 

import { Container, Typography, CircularProgress, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CoachListPage = () => {
    const location = useLocation();
    const state = location.state || {};
    const queryParams = new URLSearchParams(location.search);
    const product = state.productTitle || queryParams.get('product') || '';
    const role = state.role || queryParams.get('role') || '';
    const company = state.company || queryParams.get('company') || '';
    const [coaches, setCoaches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Scroll to the top of the page on component mount
    useEffect(() => {
        if (!product || !VALID_PRODUCTS.includes(product)) {
            setError(true);
            setErrorMessage("There is an issue with the 'product' parameter: either it is missing, incorrect, or the provided value is not valid.");
            setLoading(false);
            return;
        } else {
            window.scrollTo(0, 0);
        }
    }, [product]);

    useEffect(() => {
        const fetchCoaches = async () => {
            try {
                const query = new URLSearchParams({ product, role, company }).toString();
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/coaches/?${query}`, {
                    method: 'GET',
                });
                if (!response.ok) {
                    setError(true);
                }
                const data = await response.json();
                setCoaches(data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchCoaches();
        
    }, [product, role, company ]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        if (errorMessage) {
          return <ErrorMessagePage message={errorMessage} />; // Use the new ErrorMessagePage component
        } else {
          return <NotFoundPage />; // Fallback to NotFoundPage if no specific error message is set
        }
      }

    // if (coaches.length === 0) {
    //     return <Typography sx={{ textAlign: 'center', mt: 4 }}>No relevant coaches found.</Typography>;
    // }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <CoachList coaches={coaches} product={product} role={role} company={company} />
            {/* Wrapper Box to align icon and text properly */}
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '90%', mx: 'auto', mt: 10, mb: 10 }}>
                <SearchIcon sx={{ fontSize: 40, color: '#04003F', mr: 2, flexShrink: 0 }} />  {/* Magnifier Icon */}

                <Typography
                    variant="h4"
                    component="h1"
                    sx={{
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: { xs: '1rem', sm: '1rem', md: '1rem' },
                        lineHeight: 1.6,
                        color: '#04003F',
                        textAlign: 'left',
                    }}
                >
                    Don’t see an expert for your role or target company? Let us know at{' '}
                    <Typography
                        component="span"
                        sx={{ fontWeight: 600, display: 'inline', fontSize: 'inherit' }}
                    >
                        support@eastbadge.com
                    </Typography>
                    . We actively onboard new experts based on demand and will do our best to prioritize 
                    experts for the roles and companies you’re targeting.
                </Typography>
            </Box>
        </Container>
    );
};

export default CoachListPage;
