import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Logo assets
import MetaLogo from '../assets/home_page_assets/company-logos/meta-logo@2x.png';
import AwsLogo from '../assets/home_page_assets/company-logos/aws-logo@2x.png';
import NetflixLogo from '../assets/home_page_assets/company-logos/netflix-logo@2x.png';
import AmazonLogo from '../assets/home_page_assets/company-logos/amazon-logo-1.png';
import DoorDashLogo from '../assets/home_page_assets/company-logos/doordash-logo-full.png';

const companyLogos = [MetaLogo, NetflixLogo, AmazonLogo, DoorDashLogo, AwsLogo];

const CompanyLogos = () => {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [canScrollBack, setCanScrollBack] = useState(false);
  const [canScrollForward, setCanScrollForward] = useState(true);

  const checkForScrollPosition = () => {
    if (!scrollRef.current) return;
    const el = scrollRef.current;
    if (isMobile) {
      setCanScrollBack(el.scrollTop > 0);
      setCanScrollForward(el.scrollTop < el.scrollHeight - el.clientHeight - 1);
    } else {
      setCanScrollBack(el.scrollLeft > 0);
      setCanScrollForward(el.scrollLeft < el.scrollWidth - el.clientWidth - 1);
    }
  };

  const scroll = (direction) => {
    if (!scrollRef.current) return;
    const amount = isMobile ? 100 : 250;
    const options = {
      behavior: 'smooth',
      ...(isMobile
        ? { top: direction === 'back' ? -amount : amount }
        : { left: direction === 'back' ? -amount : amount }),
    };
    scrollRef.current.scrollBy(options);
  };

  useEffect(() => {
    const el = scrollRef.current;
    if (el) {
      el.addEventListener('scroll', checkForScrollPosition);
      checkForScrollPosition();
    }
    return () => {
      if (el) el.removeEventListener('scroll', checkForScrollPosition);
    };
  }, [isMobile]);

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
        width: '100%',
        maxWidth: { xs: '100%', sm: '90%', md: '90%' },
        margin: '0 auto',
        marginTop: { xs: '-15vh', sm: '10vh', md: '-42vh' },
        overflow: 'hidden',
        paddingX: { xs: '20px', sm: '40px', md: '60px' },
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '240px',
            position: 'relative',
          }}
        >
          {/* Up Arrow */}
          {(
            <IconButton
              onClick={() => scroll('back')}
              sx={{
                mb: 1,
                visibility: canScrollBack ? 'visible' : 'hidden', 
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 10,
              }}
            >
              <ArrowBackIosNewIcon sx={{ transform: 'rotate(90deg)', color: '#04003F' }} />
            </IconButton>
          )}

          {/* Scrollable Logo Container */}
          <Box
            ref={scrollRef}
            onScroll={checkForScrollPosition}
            sx={{
              flex: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '40px',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
              px: 1,
              // paddingTop: '10px',
              // paddingBottom: '40px',
            }}
          >
            {companyLogos.map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Company Logo ${index}`}
                sx={{
                  width: '80px',
                  height: 'auto',
                  maxHeight: '25px',
                  objectFit: 'contain',
                  flexShrink: 0,
                  ...(logo === DoorDashLogo && { width: '140px' }),
                  ...(logo === AwsLogo && { width: '70px' }),
                }}
              />
            ))}
          </Box>

          {/* Down Arrow */}
          {(
            <IconButton
              onClick={() => scroll('forward')}
              sx={{
                mt: 1,
                visibility: canScrollForward ? 'visible' : 'hidden',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                zIndex: 10,
              }}
            >
              <ArrowForwardIosIcon sx={{ transform: 'rotate(90deg)', color: '#04003F' }} />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {/* Left Arrow */}
          {canScrollBack && (
            <IconButton
              onClick={() => scroll('back')}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                '&:hover svg': {
                  transform: 'scale(1.3)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: '#04003F' }} />
            </IconButton>
          )}

          {/* Scrollable Logo Container */}
          <Box
            ref={scrollRef}
            onScroll={checkForScrollPosition}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
              gap: { sm: '100px', md: '140px' },
              justifyContent: 'flex-start',
              alignItems: 'center',
              p: 1,
            }}
          >
            {companyLogos.map((logo, index) => (
              <Box
                key={index}
                component="img"
                src={logo}
                alt={`Company Logo ${index}`}
                sx={{
                  width: { sm: '90px', md: '100px' },
                  height: 'auto',
                  maxHeight: { sm: '30px', md: '40px' },
                  objectFit: 'contain',
                  flexShrink: 0,
                  ...(logo === DoorDashLogo && {
                    width: { sm: '150px', md: '150px' },
                  }),
                  ...(logo === AwsLogo && {
                    width: { sm: '80px', md: '80px' },
                  }),
                }}
              />
            ))}
          </Box>

          {/* Right Arrow */}
          {canScrollForward && (
            <IconButton
              onClick={() => scroll('forward')}
              sx={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                zIndex: 10,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                '&:hover svg': {
                  transform: 'scale(1.3)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <ArrowForwardIosIcon sx={{ color: '#04003F' }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CompanyLogos;
