// src/pages/FAQs.jsx

import React, { useState } from 'react';
import { VALID_PRODUCTS } from '../constants'; 
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQPage = () => {
  return (
    <Box sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 4 }}>
      <FAQSection product={null} validProducts={VALID_PRODUCTS} /> {/* Passing validProducts to display FAQs */}
    </Box>
  );
};

const FAQ = ({ question, answer }) => (
  <Accordion sx={{ boxShadow: 'none', background: 'transparent', mb: 1, "&:before": { display: 'none' } }}>
    <AccordionSummary 
      expandIcon={<ExpandMoreIcon />} 
      sx={{ 
        backgroundColor: '#FFFFFF', 
        color: '#04003F',        
        borderRadius: '4px', 
        '&:hover': { 
                 backgroundImage: 'linear-gradient(180deg, #f5f5f5 0%, #FFFFFF 100%)' // Darker gradient on hover
                },
        pl: { xs: 0, sm: 3, md: 4 },
        pr: { xs: 0, sm: 0, md: 0 },
        }}
    >
      <Typography 
        sx={{ 
          fontWeight: 500, 
          cursor: 'pointer' 
        }}
      >
        {question}
      </Typography>
    </AccordionSummary>
    <AccordionDetails 
      sx={{ 
        backgroundColor: 'transparent', 
        color: '#04003F',
        pt: 2, 
        pb: 2.5, 
        pl: { xs: 0, sm: 3, md: 4 },
        pr: { xs: 0, sm: 8, md: 8 },
      }}
    >
      <Typography>{answer}</Typography>
    </AccordionDetails>
  </Accordion>
);

const FAQCategory = ({ category, faqs, selectedFAQ, selectedType }) => {
  const isSelected = selectedFAQ === selectedType; // Check if this section is active

  return (
    <Accordion 
      sx={{ 
        boxShadow: 'none', 
        background: 'transparent', 
        mb: 2,
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        sx={{ 
          backgroundImage: isSelected ? 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%) !important' : 'none', 
          backgroundColor: isSelected ? '#FFFFFF !important' : '#fafafa', 
          color: isSelected ? "#04003F !important" : "#04003F", 
          borderColor: '#F5973A',
          fontWeight: isSelected ? "bold" : "normal",
          borderRadius: '4px',
          '& .MuiTypography-root': { 
            color: isSelected ? "#04003F !important" : "#04003F", 
            fontWeight: isSelected ? "bold" : "normal"
          },
          '&:hover': { 
            backgroundImage: isSelected ? 'linear-gradient(180deg, #f5f5f5 0%, #FFFFFF 100%) !important' : 'none',
            backgroundColor: isSelected ? '#f0f0f0 !important' : '#f0f0f0'
          }
        }}
      >
        <Typography>{category}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {faqs.map((faq, index) => (
          <FAQ key={index} question={faq.question} answer={faq.answer} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};


const FAQSection = ({ product, validProducts }) => {
  const [selectedFAQ, setSelectedFAQ] = useState('candidate'); // Default to Candidate FAQs

  const handleFAQChange = (event, newFAQ) => {
    if (newFAQ !== null) {
      setSelectedFAQ(newFAQ);
    }
  };

  // **Candidate FAQs**
  const candidateFAQs = [
    {
      category: "Session",
      faqs: [
        { 
          question: "What can I expect from a mock session?", 
          answer: ( 
            <>
              Mock sessions are 1:1 practice interviews conducted by your selected coach, designed to simulate a 
              real interview experience with someone from your target role and company. Candidates typically 
              book 1-hour sessions, with most using the time for a full-length mock interview, followed by detailed 
              feedback on their responses. Some choose to focus on specific areas, such as the interview process, 
              company culture, or strategies to improve their performance. Each session is designed to help you 
              refine your approach and maximize your chances of interview success.
            </>
          )
        },
        { 
          question: "What if I didn’t get value from my session? Can I get a refund?", 
          answer: ( 
            <>
              Yes, if you’re not satisfied with your session, you can request a refund within 12 hours of the 
              session’s end time. You’ll receive a full refund, minus any payment processing fees charged by our 
              payment provider. Neither the coach nor EastBadge is compensated when a refund is issued. We ask 
              that you submit your refund request promptly, as we need to process coach payments shortly after 
              the session.
            </>
          )
        },
        { 
          question: "Can I cancel my session?", 
          answer: ( 
            <>
              <Typography>
                Yes, you can cancel your session anytime before the scheduled start time. You’ll receive a full refund, 
                minus any payment processing fees charged by our payment provider. EastBadge does not charge a cancellation fee.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                We appreciate advance notice for cancellations so your coach is informed in time and their schedule is 
                respected. Coaches dedicate their time to helping candidates, and early notification allows them to 
                adjust their plans accordingly.
              </Typography>
            </>
          )
        },
        { 
          question: "Can I reschedule or modify my session?", 
          answer: ( 
            <>
              <Typography>
              Yes, you can reschedule or modify your session. While we work on adding this feature to the platform, 
              please email us at <strong>support@eastbadge.com</strong> for any rescheduling or modification requests.
              </Typography>

              <Typography sx={{ mt: 2 }}>
              Reschedules are subject to the coach’s availability, and we appreciate advance notice for any changes. 
              This helps ensure coaches are informed in time and can adjust their schedules accordingly.
              </Typography>
            </>
          )
        },
        { 
          question: "Can I get a first-time discount or a discount for booking multiple sessions?", 
          answer: ( 
            <>
              <Typography>
                No, we do not offer first-time or bulk session discounts. 
              </Typography>

              <Typography sx={{ mt: 2 }}>
                At EastBadge, we focus on keeping our pricing among the lowest compared to similar platforms. 
                We achieve this by optimizing costs across product, marketing, and operations while maintaining 
                minimal margins—often close to zero. As a result, our listed prices are already the best and most 
                competitive we can offer, and we do not provide additional discounts.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Our goal is to deliver high-quality mock interview experiences at the most accessible rates to help 
                candidates prepare effectively.
              </Typography>
            </>
          ),
        },
        { 
          question: "Can I purchase multiple sessions as a bundle with the same coach to track progress over time?", 
          answer: ( 
            <>
              You can book multiple sessions individually, based on the coach's availability. We don’t offer bundles yet, 
              but if you’d like us to prioritize this feature, contact us at <strong>support@eastbadge.com</strong>.
            </>
          ),
        }
      ]
    },
    {
      category: "Privacy",
      faqs: [
        { 
          question: "What measures does EastBadge take to protect my privacy?", 
          answer: (
            <>
              <Typography>
                At EastBadge, protecting user and coach privacy is our top priority. We ensure anonymity, privacy and data 
                security through a two-step approach:
              </Typography>
        
              <Box component="ol" sx={{ pl: 3, mt: 2 }}>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Minimal Data Collection</strong> – We only ask for the necessary information when you book a session. 
                  The only required identifier is your email, and even sharing your name is completely optional.
                </Typography>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Strict Confidentiality</strong> – We do not share your identity with anyone, including coaches. Coaches 
                  only receive essential details such as your target role, company, and interview type—just enough to provide a 
                  high-quality mock interview experience.
                </Typography>
              </Box>
        
              <Typography sx={{ mt: 2 }}>
                Additionally, during your session, you have full control over your privacy preferences—you can choose to keep it 
                audio-only or turn on video if you’re comfortable.
              </Typography>
        
              <Typography sx={{ mt: 2 }}>
              For more details, please refer to our{" "}
                <a href="https://www.eastbadge.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "#04003F", textDecoration: "underline" }}>
                  Privacy Policy
                </a>.
              </Typography>
            </>
          )
        },
        { 
          question: "Can I verify the coach's identity and see their profile before booking a session?", 
          answer: ( 
            <>
              <Typography>
                EastBadge verifies each coach’s identity before they join the platform. To maintain anonymity for 
                both users and coaches, personal identity details are not shared.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Users can view anonymized coach profiles, which include their role, experience, reviews, and ratings, 
                giving them the insights needed to make an informed booking decision. Likewise, coaches only receive relevant 
                details about the candidate’s target role, company, and interview type—ensuring a high-quality mock interview 
                experience while maintaining privacy.
              </Typography>
            </>
          ),
        },
      ]
    },
    {
      category: "Additional",
      faqs: [
        { 
          question: "What if my question isn’t answered here?", 
          answer: ( 
            <>
              If you don’t see your question here, feel free to email us at <strong>support@eastbadge.com</strong>. 
              You will receive a response within a few hours.
            </>
          )
        },
      ]
    }
  ];

  // **Coach (Expert) FAQs**
  const coachFAQs = [
    {
      category: "Session",
      faqs: [
        { 
          question: "How are mock sessions conducted?", 
          answer: ( 
            <>
              <Typography>
                Once a candidate books a session, both the candidate and coach receive a confirmation email and calendar invitation.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Sessions are at least audio-based to ensure a productive mock interview experience. Screen sharing can be used 
                as needed to facilitate a more effective session. Video is optional—both the candidate and coach can decide 
                based on their comfort level. However, we aim to simulate a real interview experience, which typically involves 
                video. Certain aspects of feedback may also be more effective when video is enabled. For this reason, we encourage 
                keeping video on, but the final decision is up to both the candidate and coach.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                To ensure privacy, sessions are not recorded, and no transcripts are stored. We aim to provide a fully anonymous 
                experience. If you have suggestions for tools or technology that could enhance anonymity, feel free to share 
                them at <strong>support@eastbadge.com</strong>.
              </Typography>
            </>
          ),
        },
        { 
          question: "Can I cancel my session?", 
          answer: ( 
            <>
              <Typography>
                Yes, you can cancel your session. If you need to cancel, please inform us as soon as possible so the candidate can 
                be notified in time.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Repeated cancellations may affect your profile visibility to ensure candidates have a reliable booking experience.
              </Typography>
            </>
          ),
        },
        { 
          question: "Can I reschedule my session?", 
          answer: ( 
            <>
              <Typography>
                Yes, you can reschedule your session subject to candidate's availability. We appreciate advance notice for any 
                requests as it helps ensure candidates are informed in time and can adjust their schedules accordingly.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                While we work on adding this feature to the platform, please email us at <strong>support@eastbadge.com</strong> 
                for any rescheduling or modification requests.
              </Typography>
            </>
          ),
        },
        { 
          question: "What are my responsibilities as a coach, and are there any restrictions on what I can share with candidates?", 
          answer: ( 
            <>
              <Typography>
                As a coach, you have two key responsibilities:
              </Typography>

              <Typography sx={{ mt: 1 }}>
                First, conducting the session. Use the session time in a way that best supports the candidate—they are the customer. 
                Most sessions involve mock interviews, where you simulate a real interview by asking relevant questions and providing 
                honest feedback. In some cases, particularly for 15- or 30-minute sessions, candidates may prefer to focus on interview 
                guidance, company culture insights, or Q&A instead of a full mock interview. Please accommodate their needs accordingly. 
                The ultimate goal is to help candidates improve their chances of success.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Second, providing feedback (verbal & written). We recommend planning your session to allow time for discussing feedback 
                verbally with the candidate before the session ends. After the session, you must submit written feedback to  
                <strong> support@eastbadge.com</strong> within 24 hours. Most coaches provide this within an hour, and payouts are processed 
                only after written feedback is submitted.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Lastly, coaches must not share any confidential, proprietary, or internal information that should not be disclosed externally. 
                If you’re unsure whether something is appropriate to share, it’s best to err on the side of caution.
              </Typography>
            </>
          ),
        },
      ]
    },
    {
      category: "Payments",
      faqs: [
        { 
          question: "When will I receive my payment?", 
          answer: ( 
            <>
              <Typography>
                Payments are processed after the session is completed and you have submitted written feedback on the candidate’s performance.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Candidates have up to 12 hours after the session to request a refund if needed. Your payout will be initiated as soon as we 
                confirm that the candidate found the session valuable and you have submitted the required feedback. In most cases, payouts are 
                processed within a few hours of the session but will be initiated no later than 24 hours after the session, provided the 
                written feedback has been submitted.
              </Typography>
            </>
          ),
        },
        { 
          question: "What happens if a candidate cancels a session scheduled with me?", 
          answer: ( 
            <>
              <Typography>
                Candidate cancellations are rare, and so far, this has not occurred. However, if a candidate cancels before the session 
                begins, they receive a refund of their session fee. At this time, there is no monetary compensation for coaches in the 
                event of a cancellation.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                We recognize that cancellations can be inconvenient, and we appreciate your flexibility in such rare cases.
              </Typography>
            </>
          ),
        },
        { 
          question: "What happens to my payment if a candidate requests a refund?", 
          answer: ( 
            <>
              <Typography>
                So far, no candidate has requested a refund. However, in the rare event that a refund is issued, the session fee 
                will be fully refunded to the candidate, and no payout will be made to the coach.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Since this situation has never occurred, we have not had to address it directly. However, we understand this may 
                be a concern for coaches. If this ever happens and you feel it was unfair, please reach out to us—we’ll make it right.
              </Typography>
            </>
          ),
        },
      ]
    },
    {
      category: "Privacy & Communication",
      faqs: [
        { 
          question: "How does EastBadge protect my privacy?", 
          answer: (
            <>
              <Typography>
                At EastBadge, protecting the privacy of both users and coaches is our top priority. We ensure anonymity, privacy, 
                and data security through a two-step approach:
              </Typography>
        
              <Box component="ol" sx={{ pl: 3, mt: 2 }}>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Minimal Data Collection & Anonymized Profiles</strong> – 	We only collect the essential information needed 
                  for you to onboard with EastBadge. The only personally identifiable information (PII) we require is your email, 
                  which is used for communication and account-related purposes. We do not require your name, picture, or any other 
                  PII, nor do we publish such details on the platform. Instead, we display your role, position, experience, reviews, 
                  and ratings to help candidates make an informed decision when booking a session.

                </Typography>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Strict Confidentiality</strong> – Your identity is not shared with anyone, including candidates. If a 
                  candidate requests your LinkedIn profile or name, we prioritize your privacy over booking the session and do not 
                  disclose your details. Privacy and anonymity are core principles of our platform.
                </Typography>
              </Box>
        
              <Typography sx={{ mt: 2 }}>
                Additionally, during sessions, you have full control over your privacy preferences. You can choose to keep the session 
                audio-only or turn on video if you feel comfortable.
              </Typography>
        
              <Typography sx={{ mt: 2 }}>
              For more details, please refer to our{" "}
                <a href="https://www.eastbadge.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "#04003F", textDecoration: "underline" }}>
                  Privacy Policy
                </a>.
              </Typography>
            </>
          )
        },
        { 
          question: "Can coaches and candidates communicate after a session while following platform guidelines? Can I connect with other coaches on the platform?", 
          answer: (
            <>
              <Typography>
                EastBadge’s Terms of Use do not permit direct communication between coaches and candidates outside of EastBadge-assisted 
                channels. However, if a candidate needs to follow up with a coach after a session, the following options are available:
              </Typography>
        
              <Box component="ol" sx={{ pl: 3, mt: 2 }}>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Booking a shorter follow-up session</strong> – 	We encourage candidates to book a 15- or 30-minute session if 
                  they have additional questions.
                </Typography>
                <Typography component="li" sx={{ mt: 1 }}>
                  <strong>Emailing via EastBadge support</strong> – If another session isn’t feasible and the question can be addressed 
                  over email, the candidate can send their query to <strong> support@eastbadge.com</strong>, and we will relay it to the coach. 
                  In this case, our support team acts as the communication medium.
                </Typography>
              </Box>
        
              <Typography sx={{ mt: 2 }}>
                Currently, there is no EastBadge-assisted way for coaches to communicate anonymously with candidates or other coaches 
                after a session. However, we are actively exploring solutions such as anonymized chat or Discord groups to facilitate 
                discussions while maintaining privacy. If you have suggestions or strong preferences for a tool, we’d love to hear your thoughts.
              </Typography>
            </>
          )
        },
      ]
    }
  ];

  const defaultFaqs = {
    candidate: candidateFAQs,
    coach: coachFAQs
  };

  const faqs = defaultFaqs;


  return (
    <Box sx={{ mb: 4, px: { xs: 2, md: 4 } }}>
      <Typography
        variant="h2"
        sx={{
          fontFamily: 'Roboto',
          fontSize: { xs: '1.1rem', sm: '2.2rem', md: '3rem' },
          lineHeight: 1.2,
          color: '#04003F',
          marginTop: '10px',
          marginLeft: '20px',
          marginBottom: '50px',
        }}
      >
        FAQs
      </Typography>

      {/* Toggle Button for Switching Between Candidate & Coach FAQs */}
      <Box sx={{ 
              display: 'flex', 
              alignItems: { xs: 'stretch', sm: 'center' },
              flexDirection: { xs: 'column', sm: 'row', md: 'row' }, 
              gap: 2, 
              mb: 3, 
              width: '100%' 
            }}
      > 
        <ToggleButtonGroup
          value={selectedFAQ}
          exclusive
          onChange={handleFAQChange}
          sx={{
            backgroundColor: "transparent",
            borderRadius: "4px",
            display: "flex",
            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            width: { xs: '50%', sm: '40%' },
            minWidth: '140px', // Ensure buttons are wide enough
            whiteSpace: 'nowrap',
            mx: "auto",
            "& .MuiToggleButton-root": {
              flex: 1,
              backgroundImage: 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%)', // Dark gradient default
              color: '#04003F', // Yellow text
              '&:hover': { 
                 backgroundImage: 'linear-gradient(180deg, #f5f5f5 0%, #FFFFFF 100%)' // Darker gradient on hover
                },
              borderColor: '#F5973A',
              textTransform: 'capitalize',
              height: '47px', 
              ml: '9px',
              mb: '40px',
              fontSize: '1.0rem',
              borderRadius: "4px",
              '&.Mui-selected': {
                backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)',
                color: "#04003F",
                fontWeight: "bold",
                '&:hover': { 
                 backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)' // Darker gradient on hover
                },
              }
            }
          }}
        >
          <ToggleButton value="candidate">Candidate</ToggleButton>
          <ToggleButton value="coach">Coach (Expert)</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* FAQ Categories */}
      {faqs[selectedFAQ].map((category, index) => (
        <FAQCategory key={index} category={category.category} faqs={category.faqs} selectedFAQ={selectedFAQ} selectedType={selectedFAQ} />
      ))}
    </Box>
  );
};

export default FAQPage;
