
import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import SignOut from '../pages/SignOut';
import eastbadge_logo from '../assets/eastbadge_logo_blue.png';

import { AppBar, Toolbar, Typography, Button, Box, Container, Menu, MenuItem, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const LayoutHeader = () => {

  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  // State for user menu
  const [anchorEl, setAnchorEl] = useState(null);

  // State for Products & Company dropdowns
  const [productsMenu, setProductsMenu] = useState(null);
  const [companyMenu, setCompanyMenu] = useState(null);
  
  // State for Mobile Menu
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);

  // Handle Account Menu
  const openAccountMenu = (event) => {
    document.body.style.overflow = 'hidden'; // Prevent screen shift
    setAnchorEl(event.currentTarget);
  };

  const closeAccountMenu = () => {
    document.body.style.overflow = 'auto'; // Restore scrolling
    setAnchorEl(null);
  };

  // Handle Products Menu
  const openProductsMenu = (event) => {
    document.body.style.overflow = 'hidden'; 
    setProductsMenu(event.currentTarget);
  };

  const closeProductsMenu = () => {
    document.body.style.overflow = 'auto'; 
    setProductsMenu(null);
  };

  // Handle Company Menu
  const openCompanyMenu = (event) => {
    document.body.style.overflow = 'hidden'; 
    setCompanyMenu(event.currentTarget);
  };

  const closeCompanyMenu = () => {
    document.body.style.overflow = 'auto'; 
    setCompanyMenu(null);
  };

  const openMobileMenu = (event) => {
    document.body.style.overflow = 'hidden'; 
    setMobileMenuAnchor(event.currentTarget);
  };

  const closeMobileMenu = () => {
    document.body.style.overflow = 'auto'; 
    setMobileMenuAnchor(null);
  };

  const goToAccount = () => {
    navigate('/account');
    closeAccountMenu();
  };
  
  const goToSignIn = () => {
    closeAccountMenu();
    navigate('/signin', { state: { from: location } });
  };

  return (

    <AppBar 
      position="static" 
      sx={{
        backgroundColor: '#fff',
        color: '#fff',
        minHeight: { xs: 70, sm: 120 },
        boxShadow: '0px 6px 6px -2px rgba(0, 0, 0, 0.1)',
        '@media (max-width:600px)': { minHeight: 64 }
    }}>

      <Container maxWidth="lg">
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            lineHeight: '10px',
            // fontSize: '2.2rem',
            gap: { xs: '0px', sm: '20px', md: '40px'},
            mt: {xs: 2, sm: 3, md: 3},
            // '@media (max-width:600px)': { fontSize: '1.8rem', lineHeight: '64px' }
          }}
        >

          {/* Logo Section */}
          <Box 
            sx={{ 
              // flex: '0 0 auto',
              width: { xs: '60%', sm: 'auto' },
              display: 'flex', 
              justifyContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center', 
              ml: {xs: 1, sm: 3, md: 5} 
            }}
          >
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img
                src={eastbadge_logo}
                alt="EastBadge Logo"
                style={{
                  height: '50px',
                  width: 'auto',
                  objectFit: 'contain',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: isMobile ? '150px' : undefined,
                  '@media (max-width: 768px)': { height: '45px' },
                  '@media (max-width: 480px)': { height: '35px' }
                }}
              />
            </RouterLink>
          </Box>

          {/* Buttons Section (Products, Company) */}
          <Box
            sx={{
              width: { xs: '50%', sm: 'auto' },
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: { xs: 1, sm: 3 },
              flexGrow: 1, // Pushes "Register/Sign In" to the right on large screens
            }}
          >
            {/* Mobile View: Hamburger Menu */}
            {isMobile && (
              <>
                <IconButton 
                  onClick={openMobileMenu}
                  sx={{
                    color: '#04003F',
                    ml: 9, 
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={mobileMenuAnchor}
                  open={Boolean(mobileMenuAnchor)}
                  onClose={closeMobileMenu}
                >
                  <MenuItem
                    onClick={(e) => {
                      openProductsMenu(e);
                      closeMobileMenu();
                    }}
                    sx={{
                      color: '#F5973A',
                    }}
                  >
                    Products
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      openCompanyMenu(e);
                      closeMobileMenu();
                    }}
                    sx={{
                      color: '#F5973A',
                    }}
                  >
                    Company
                  </MenuItem>
                </Menu>
              </>
            )}

            {/* Show Products & Company buttons only on larger screens */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
              {/* Products Button */}
              <Box sx={{ position: 'relative' }}>
                <Button
                  onClick={openProductsMenu}
                  sx={{ color: '#04003F', textTransform: 'none', fontSize: '1rem', '&:hover': { backgroundColor: '#fafafa' } }}
                >
                  Products
                </Button>
                <Menu
                  anchorEl={productsMenu}
                  open={Boolean(productsMenu)}
                  onClose={closeProductsMenu}
                  sx={{ '& .MuiPaper-root': { padding: '5px' } }}
                >
                  <Box sx={{ p: 2, width: { xs: '150px', sm: '170px', md: '340px' } }}>

                    <Typography sx={{ fontSize: '1rem', fontWeight: 600, color: '#04003F' }}>
                      Ace with Mocks
                    </Typography>

                    <Typography sx={{ fontSize: '0.85rem', color: '#04003F', mb: 1 }}>
                      Practice mock interviews with experts. Simulate real interview experience and ace your interviews.
                    </Typography>

                    {/* Browse Experts & Become an Expert */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: { xs: 'center', sm: 'space-between' },
                        gap: { xs: '3px', sm: '6px' },
                        flexDirection: { xs: 'column', sm: 'row' },
                        px: 1
                      }}
                    >
                      <MenuItem
                        sx={{ color: '#F5973A', flex: 1, textAlign: 'center' }}
                        onClick={() => {
                          navigate("/coaches?product=Ace%20with%20Mocks&role=&company=");
                          closeProductsMenu();
                        }}
                      >
                        Browse Experts
                      </MenuItem>
                      <MenuItem
                        component={RouterLink}
                        to="/become-an-expert"
                        sx={{ color: '#F5973A', flex: 1, textAlign: 'center' }}
                        onClick={closeProductsMenu}
                      >
                        Become an Expert
                      </MenuItem>
                    </Box>
                  </Box>
                </Menu>
              </Box>

              {/* Company Button */}
              <Box sx={{ position: 'relative' }}>
                <Button
                  onClick={openCompanyMenu}
                  sx={{ color: '#04003F', textTransform: 'none', fontSize: '1rem', '&:hover': { backgroundColor: '#fafafa' } }}
                >
                  Company
                </Button>
                <Menu
                  anchorEl={companyMenu}
                  open={Boolean(companyMenu)}
                  onClose={closeCompanyMenu}
                  sx={{ '& .MuiPaper-root': { padding: '5px' } }}
                >
                  <MenuItem
                    component={RouterLink}
                    to="/faqs"
                    sx={{ color: '#F5973A', fontSize: '1rem' }}
                    onClick={closeCompanyMenu}
                  >
                    FAQs
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>

          {/* Right Side: Register/Sign In */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              mt: { xs: 1, sm: 0 },
              mr: { xs: 0, sm: 7, md: 11 }
            }}
          >
            {/* MOBILE: Always show icon, use menu */}
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <IconButton 
                onClick={openAccountMenu} 
                sx={{ 
                  color: '#04003F',
                  pr: 4,
                  pb: 2
                }}
              >
                <AccountCircleIcon sx={{ fontSize: 28 }} />
              </IconButton>

              <Menu
                id="mobile-account-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={closeAccountMenu}
              >
                {currentUser ? (
                  <>
                    <MenuItem onClick={goToAccount} sx={{ color: '#F5973A', py: 1.5 }}>
                      My Account
                    </MenuItem>
                    <MenuItem onClick={closeAccountMenu} sx={{ color: '#F5973A', py: 1.5 }}>
                      <Typography sx={{ lineHeight: 'normal' }}>
                        <SignOut />
                      </Typography>
                    </MenuItem>
                  </>
                ) : (
                  <MenuItem onClick={goToSignIn} sx={{ color: '#F5973A', py: 1.5 }}>
                    Register/ Sign In
                  </MenuItem>
                )}
              </Menu>
            </Box>

            {/* DESKTOP: Full button layout */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              {currentUser ? (
                <>
                  <Button
                    onClick={openAccountMenu}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    sx={{
                      color: '#04003F',
                      textTransform: 'none',
                      fontSize: '1rem',
                      gap: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': { backgroundColor: '#fafafa' }
                    }}
                  >
                    <AccountCircleIcon sx={{ fontSize: 24 }} />
                    Account
                  </Button>

                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={Boolean(anchorEl)}
                    onClose={closeAccountMenu}
                  >
                    <MenuItem onClick={goToAccount} sx={{ color: '#F5973A', py: 1.5 }}>
                      My Account
                    </MenuItem>
                    <MenuItem onClick={closeAccountMenu} sx={{ color: '#F5973A', py: 1.5 }}>
                      <Typography sx={{ lineHeight: 'normal' }}>
                        <SignOut />
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Button
                  onClick={goToSignIn}
                  sx={{
                    color: '#04003F',
                    textTransform: 'none',
                    fontSize: '1rem',
                    '&:hover': { backgroundColor: '#fafafa' }
                  }}
                >
                  Register/ Sign In
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default LayoutHeader;