import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Typography, IconButton, Select, MenuItem, FormControl, InputLabel, Grid, Paper, Snackbar, Alert } from '@mui/material';
import { Edit as FiEdit2, Close as FiX, Check as FiCheck, Clear as ClearIcon } from '@mui/icons-material';
import validator from 'validator';

const UserInfoForm = ({
  onSubmitUserInfo,
  initialTargetRole = '',
  initialTargetCompany = '',
  initialEmail = '',
  initialSessionFocus = '',
  product,
  submissionStatus,
  onPatchUserInfo,
  onBackClick
}) => {

  const [email, setEmail] = useState(initialEmail);
  const [targetRole, setTargetRole] = useState(initialTargetRole);
  const [targetCompany, setTargetCompany] = useState(initialTargetCompany);
  // Set the initial state based on the product
  // const initialSessionFocus = product === "Ace with Mocks" ? "letCoachDecide" : product === "Burnout to Break Free" ? "letCoachDecide" : '';
  const [sessionFocus, setSessionFocus] = useState(initialSessionFocus);

  // State to keep track of the original (last successfully saved) values
  const [originalValues, setOriginalValues] = useState({
    email: initialEmail,
    targetRole: initialTargetRole,
    targetCompany: initialTargetCompany,
    sessionFocus: initialSessionFocus,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEditingRole, setIsEditingRole] = useState(!initialTargetRole);
  const [isEditingCompany, setIsEditingCompany] = useState(!initialTargetCompany);
  const [isEditingEmail, setIsEditingEmail] = useState(!initialEmail);
  const [isEditingSessionFocus, setIsEditingSessionFocus] = useState(() => {
    // If the form is already submitted, make the field non-editable
    // Otherwise, make it editable on the first load
    return submissionStatus === 'Submitted' ? false : true;
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // feedbackMessage and feedbackMessageType are status messages to convey Success or Failure 
  // of the Update on the UI when the user clicks the 'Update' icon post successful submission of the form.
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackMessageType, setFeedbackMessageType] = useState('');

  // Refs for input fields to access DOM nodes directly
  const emailRef = useRef(null);
  const roleRef = useRef(null);
  const companyRef = useRef(null);
  const sessionFocusRef = useRef(null);

  useEffect(() => {
    const checkFormValidity = () => {
      if (!validator.isEmail(email)) {
        setErrorMessage("Please enter a valid email address.");
        return false;
      }
      if (!targetRole.trim() || !targetCompany.trim()) {
        setErrorMessage("Role and company fields cannot be empty.");
        return false;
      }
      setErrorMessage("");
      return true;
    };
    setIsFormValid(checkFormValidity());
  }, [email, targetRole, targetCompany]);

  // Submission status after POST API call in ConfirmationAndPaymentPage.jsx. This is to update the Button text appropriately.
  useEffect(() => {
    if (submissionStatus === 'Submitted') {
      setIsSubmitted(true);
    }
  }, [submissionStatus]);

  // A useEffect hook to check for autofill and apply styles accordingly
  useEffect(() => {
    if (emailRef.current && roleRef.current && companyRef.current && sessionFocusRef.current) {
      const inputs = [emailRef.current, roleRef.current, companyRef.current, sessionFocusRef.current];
      const styleAutofilledInputs = () => {
        inputs.forEach(input => {
          if (input instanceof HTMLElement && input.matches && input.matches(':autofill')) {
            input.classList.add('autofilled');
          }
        });
      };
      styleAutofilledInputs();
      const intervalId = setInterval(styleAutofilledInputs, 200);
      return () => clearInterval(intervalId);
    }
  }, [emailRef, roleRef, companyRef, sessionFocusRef]);

  // Utility function to handle POST request for updates
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      // Handle the case where the form is submitted while invalid
      alert('Invalid user details entered.');
      return;
    }
    try {
      const response = onSubmitUserInfo({ email, targetRole, targetCompany, sessionFocus });
      response.then((res) => {
        if (res.ok) {
          // Update original values upon successful form submission
          setOriginalValues({ email, targetRole, targetCompany, sessionFocus });
          
          setIsSubmitted(true);
          
          setIsEditingEmail(false);
          setIsEditingRole(false);
          setIsEditingCompany(false);
          setIsEditingSessionFocus(false);
          
          // Set success feedback message
          setFeedbackMessage('Submitted successfully.');
          setFeedbackMessageType('success');
        } else {
          // Handle unsuccessful submission if needed
          setFeedbackMessage('Failed to submit. Please try again.');
          setFeedbackMessageType('error');
        }
      });
    } catch (error) {
        setErrorMessage("Failed to submit. Please try again.");

        setFeedbackMessage('Failed to submit. Please try again.');
        setFeedbackMessageType('error');
    } finally {
      // Optionally, clear the feedback message after a delay
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackMessageType('');
      }, 2000);
    }
  };

  // Utility function to handle PATCH request for updates
  const handleUpdate = async (field, value) => {
    if (!isFormValid) {
      // Handle the case where the form is submitted while invalid
      alert('Invalid user details entered.');
      revertToOriginalValues();
      return;
    }
    const patchData = { [field]: value };
    try {
      const response = await onPatchUserInfo(patchData);

      if (response && response.ok) {
        // If the PATCH request is successful, parse the response (if necessary) and set the feedback message.
        setOriginalValues(prev => ({ ...prev, [field]: value }));
        setFeedbackMessage('Updated successfully.');
        setFeedbackMessageType('success');
      } else {
        // If the PATCH request fails, parse the response to get error details and show an error message.
        revertToOriginalValues();
        const errorData = await response.json(); // Assuming your API returns JSON with an error message
        setFeedbackMessage(errorData.message || 'Failed to update. Please Try again.');
        setFeedbackMessageType('error');
      }
    } catch (error) {
      revertToOriginalValues();
      // Handle network errors or other issues with the PATCH request
      setFeedbackMessage('Failed to update. Please Try again.');
      setFeedbackMessageType('error');
    } finally {
      // Optionally, clear the feedback message after a delay
      setTimeout(() => {
        setFeedbackMessage('');
        setFeedbackMessageType('');
      }, 2000); // Clear the message after 2 seconds
    }
  };

  // revert to original values if "Update" fails or when the user clicks "Cancel" icon after making changes to the field value
  const revertToOriginalValues = (fieldName) => {
    switch (fieldName) {
      case 'email':
        setEmail(originalValues.email);
        break;
      case 'role':
        setTargetRole(originalValues.targetRole);
        break;
      case 'company':
        setTargetCompany(originalValues.targetCompany);
        break;
      case 'sessionFocus':
        setSessionFocus(originalValues.sessionFocus);
        break;
      default:
        // If no specific field is passed, revert all fields
        setEmail(originalValues.email);
        setTargetRole(originalValues.targetRole);
        setTargetCompany(originalValues.targetCompany);
        setSessionFocus(originalValues.sessionFocus);
        break;
    }
  };

  // Toggle the editability of a field
  const makeFieldEditable = (fieldName) => {
    switch (fieldName) {
      case 'email':
        setIsEditingEmail(true);
        break;
      case 'role':
        setIsEditingRole(true);
        break;
      case 'company':
        setIsEditingCompany(true);
        break;
      case 'sessionFocus':
        setIsEditingSessionFocus(true);
        break;
      default:
        break;
    }
  };

  const cancelEdit = (fieldName) => {
    revertToOriginalValues(fieldName);
    // Reset to initial values if needed, or just switch off the editing mode
    switch (fieldName) {
      case 'email':
        setIsEditingEmail(false);
        break;
      case 'role':
        setIsEditingRole(false);
        break;
      case 'company':
        setIsEditingCompany(false);
        break;
      case 'sessionFocus':
        setIsEditingSessionFocus(false);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" component="h2" sx={{ mb: 2, ml: 2, mt: 4 }}>
        Your details
      </Typography>
      <Paper 
        sx={(theme) => ({ 
          mt: 2, 
          mb: 4, 
          p: 0, 
          border: 0, 
          borderColor: '#e6e5e5', 
          borderRadius: '4px', 
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            margin: '0px 0px', // Adjust for smaller screens
          }, 
        })}
        elevation={1}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <FormControl variant="outlined" sx={{ mt: 1, width: '100%' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography sx={{ textAlign: { xs: 'left', sm: 'right' }, pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 1 }, mr: { xs: 0, sm: 9 }, color: '#747474' }}>
                  Email ID  :
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6.6} sx={{ p: { xs: 0, sm: 0 }, mt: { xs: 1, sm: 2 }, ml: { xs: 0, sm: -10 } }}>
                <TextField
                  inputRef={emailRef}
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={!isEditingEmail}
                  variant="outlined"
                  fullWidth
                  sx={{
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px white inset !important',
                      WebkitTextFillColor: '#000 !important',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {isSubmitted && isEditingEmail ? (
                  <>
                    <IconButton 
                      onClick={() => cancelEdit('email')} 
                      sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => { handleUpdate('email', email); setIsEditingEmail(false); }} 
                      sx={{ 
                        color: '#fff', 
                        backgroundColor: '#1890ff', 
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#1071e5' },
                        ml: 3
                      }}
                    >
                      <FiCheck />
                    </IconButton>
                  </>
                ) : isSubmitted ? (
                  <IconButton 
                    onClick={() => makeFieldEditable('email')} 
                    sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                  >
                    <FiEdit2 />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl variant="outlined" sx={{ mt: 1, width: '100%' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography sx={{ textAlign: { xs: 'left', sm: 'right' }, pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 1 }, mr: { xs: 0, sm: 9 }, color: '#747474' }}>
                  {product === "Ace with Mocks" ? "Target role  :" :
                  product === "Burnout to Break Free" ? "Present role  :" : "Role"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6.6} sx={{ p: { xs: 0, sm: 0 }, mt: { xs: 1, sm: 2 }, ml: { xs: 0, sm: -10 } }}>
                <TextField
                  inputRef={roleRef}
                  id="targetRole"
                  type="text"
                  placeholder={product ? (product === "Ace with Mocks" ? "Enter your target role" : "Enter your present role") : "Enter your role"}
                  value={targetRole}
                  onChange={(e) => setTargetRole(e.target.value)}
                  required
                  disabled={!isEditingRole}
                  variant="outlined"
                  fullWidth
                  sx={{
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px white inset !important',
                      WebkitTextFillColor: '#000 !important',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {isSubmitted && isEditingRole ? (
                  <>
                    <IconButton 
                      onClick={() => cancelEdit('role')} 
                      sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => { handleUpdate('targetRole', targetRole); setIsEditingRole(false); }} 
                      sx={{ 
                        color: '#fff', 
                        backgroundColor: '#1890ff', 
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#1071e5' },
                        ml: 3
                      }}
                    >
                      <FiCheck />
                    </IconButton>
                  </>
                ) : (isSubmitted || !isEditingRole) ? (
                  <IconButton 
                    onClick={() => makeFieldEditable('role')} 
                    sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                  >
                    <FiEdit2 />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl variant="outlined" sx={{ mt: 1, width: '100%' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography sx={{ textAlign: { xs: 'left', sm: 'right' }, pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 1 }, mr: { xs: 0, sm: 9 }, color: '#747474' }}>
                  {product === "Ace with Mocks" ? "Target company  :" :
                  product === "Burnout to Break Free" ? "Present company  :" : "Company  :"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6.6} sx={{ p: { xs: 0, sm: 0 }, mt: { xs: 1, sm: 2 }, ml: { xs: 0, sm: -10 } }}>
                <TextField
                  inputRef={companyRef}
                  id="targetCompany"
                  type="text"
                  placeholder={product ? (product === "Ace with Mocks" ? "Enter your target company" : "Enter your present company") : "Enter your company"}
                  value={targetCompany}
                  onChange={(e) => setTargetCompany(e.target.value)}
                  required
                  disabled={!isEditingCompany}
                  variant="outlined"
                  fullWidth
                  sx={{
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px white inset !important',
                      WebkitTextFillColor: '#000 !important',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                {isSubmitted && isEditingCompany ? (
                  <>
                    <IconButton 
                      onClick={() => cancelEdit('company')} 
                      sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => { handleUpdate('targetCompany', targetCompany); setIsEditingCompany(false); }} 
                      sx={{ 
                        color: '#fff', 
                        backgroundColor: '#1890ff', 
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#1071e5' },
                        ml: 3
                      }}
                    >
                      <FiCheck />
                    </IconButton>
                  </>
                ) : (isSubmitted || !isEditingCompany) ? (
                  <IconButton 
                    onClick={() => makeFieldEditable('company')} 
                    sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                  >
                    <FiEdit2 />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </FormControl>

          <FormControl variant="outlined" sx={{ mt: 1, width: '100%' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography sx={{ textAlign: { xs: 'left', sm: 'right' }, pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 1 }, mr: { xs: 0, sm: 9 }, color: '#747474' }}>
                  Session focus  :
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6.6} sx={{ p: { xs: 0, sm: 0 }, mt: { xs: 1, sm: 2 }, mb: 4, ml: { xs: 0, sm: -10 } }}>
                <Select
                  inputRef={sessionFocusRef}
                  id="sessionFocus"
                  value={sessionFocus}
                  onChange={(e) => setSessionFocus(e.target.value)}
                  required
                  disabled={!isEditingSessionFocus}
                  variant="outlined"
                  fullWidth
                  sx={{ 
                    width: '100%',
                    '& .MuiSelect-select:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 1000px white inset !important',
                      WebkitTextFillColor: '#000 !important',
                    },
                  }}
                >
                  {product === "Ace with Mocks" && [
                    <MenuItem key="letCoachDecide" value="letCoachDecide">Let Coach Decide</MenuItem>,
                    <MenuItem key="behavioral" value="behavioral">Behavioral</MenuItem>,
                    <MenuItem key="productSense" value="productSense">Product Sense</MenuItem>,
                    <MenuItem key="productExecution" value="productExecution">Product Execution</MenuItem>,
                    <MenuItem key="coding" value="coding">Coding</MenuItem>,
                    <MenuItem key="systemDesign" value="systemDesign">System Design</MenuItem>,
                    <MenuItem key="projectDeepDive" value="projectDeepDive">Project Deep Dive</MenuItem>,
                    <MenuItem key="codeReview" value="codeReview">Code Review</MenuItem>,
                    <MenuItem key="machineCoding" value="machineCoding">Machine Coding</MenuItem>,
                    <MenuItem key="otherOrDecideDuringSession" value="otherOrDecideDuringSession">Other Or Decide During Session</MenuItem>
                  ]}
                  {product === "Burnout to Break Free" && [
                    <MenuItem key="letCoachDecide" value="letCoachDecide">Let the coach decide</MenuItem>,
                    <MenuItem key="fmla" value="fmla">Medical leave</MenuItem>,
                    <MenuItem key="pip" value="pip">Performance</MenuItem>,
                    <MenuItem key="shareDuringSession" value="shareDuringSession">Decide during session</MenuItem>
                  ]}
                  {!(product === "Ace with Mocks" || product === "Burnout to Break Free") && [
                    <MenuItem key="default" value="">Select an option</MenuItem>
                  ]}
                </Select>
              </Grid>
              <Grid item xs={2}>
                {isSubmitted && !isEditingSessionFocus ? (
                  <IconButton 
                    onClick={() => makeFieldEditable('sessionFocus')} 
                    sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                  >
                    <FiEdit2 />
                  </IconButton>
                ) : null}
                {isSubmitted && isEditingSessionFocus ? (
                  <>
                    <IconButton 
                      onClick={() => cancelEdit('sessionFocus')} 
                      sx={{ color: 'grey', backgroundColor: '#f5f5f5', '&:hover': { bgcolor: '#e0e0e0' }, borderRadius: '50%' }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => { handleUpdate('sessionFocus', sessionFocus); setIsEditingSessionFocus(false); }} 
                      sx={{ 
                        color: '#fff', 
                        backgroundColor: '#1890ff', 
                        borderRadius: '50%',
                        '&:hover': { bgcolor: '#1071e5' },
                        ml: 3
                      }}
                    >
                      <FiCheck />
                    </IconButton>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Paper>
{/* NOW */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: 4, mb: 10, mt: 2 }}> 
        <Button 
          type="button"
          onClick={onBackClick}
          variant="contained"
          sx={{ 
            p: 1, 
            backgroundColor: '#ececec', 
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
            color: 'black', 
            width: '170px', 
            height: '50px', 
            borderRadius: '4px',
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          Back
        </Button>

        <Button
          type="submit"
          disabled={submissionStatus === 'Submitted'}
          variant="contained"
          sx={{
            p: 1, 
            backgroundColor: submissionStatus === 'Submitted' || !isFormValid ? '#ececec' : 'transparent',
            backgroundImage: submissionStatus === 'Submitted' || !isFormValid ? 'none' : 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)',
            color: submissionStatus === 'Submitted' || !isFormValid ? '#a0a0a0' : '#04003F', // Adjust text color for better visibility
            '&:hover': {
              backgroundColor: submissionStatus === 'Submitted' || !isFormValid ? '#ececec' : 'transparent',
              backgroundImage: submissionStatus === 'Submitted' || !isFormValid ? 'none' : 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)',
            },
            width: '170px', 
            height: '50px', 
            borderRadius: '4px',
            textTransform: 'none',
            fontSize: '1rem',
          }}
        >
          {submissionStatus === 'Submitted' ? 'Submitted' : 'Submit'}
        </Button>
      </Box>

      <Snackbar
        open={Boolean(feedbackMessage)}
        autoHideDuration={2000}
        onClose={() => setFeedbackMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setFeedbackMessage('')} severity={feedbackMessageType} sx={{ width: '100%' }}>
          {feedbackMessage}
        </Alert>
      </Snackbar>

    </form>
  );
};

export default UserInfoForm;
