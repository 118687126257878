import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Import background assets
import HeroBannerTop from '../assets/home_page_assets/hero-banner-top-row.png';
import HeroBannerVideo from '../assets/home_page_assets/hero-banner-video.mp4';
import HeroBannerPoster from '../assets/home_page_assets/hero-banner-image.png';
import HeroBannerMask from '../assets/home_page_assets/hero-banner-center-box.svg';
import HeroBannerBottom from '../assets/home_page_assets/hero-banner-bottom.png';
import SmallerDeviceImage from '../assets/home_page_assets/hero-banner-mobile.png';

const MainHeading = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box 
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '50vh', sm: '62vh', md: '102vh' }, // Full height for large screens, adjusted for mobile
        overflow: 'hidden',
        zIndex: 2, // Ensure it's above the background image
      }}
    >
      {/* Mobile Background Image */}
      {/* {isSmallScreen && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vh',
            height: {xs: '100vh', sm: '100vh'},
            backgroundImage: `url(${SmallerDeviceImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 1,
          }}
        />
      )} */}

      {/* Top Gradient Image */}
      {!isSmallScreen && (
        <Box
          component="img"
          src={HeroBannerTop}
          alt="Hero Banner Top Row"
          sx={{
            position: 'absolute',
            top: 0, // Placed directly below the header
            left: '0px',
            width: '100%', // Adapts to all screen sizes 
            height: '25px', // As per Adobe XD specs
            objectFit: 'cover', // Ensures proper scaling
            opacity: 1,
            zIndex: 10, // Ensures it's above the video background
          }}
        />
      )}

      {/* Masking Container - Defines Visible Video Area */}
      {!isSmallScreen && (
        <Box
          sx={{
            position: 'absolute',
            top: 0, // Align as per Adobe XD
            left: '0px',
            width: '100%',
            height: '350px',
            overflow: 'hidden', // Hide parts outside this area
            WebkitMaskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)', // Masks the bottom
            maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)',
            zIndex: 3, // Above video, behind text
          }}
        >
          <video
            src={HeroBannerVideo}
            poster={HeroBannerPoster}
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: 'absolute',
              top: '0px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '1920px', // Adobe XD width
              height: '1117px', // Adobe XD height
              objectFit: 'cover',
              clipPath: 'inset(0px 0px 0px 0px round 10px)', // Proper clipping effect
            }}
          />
        </Box>
      )}

      {/* Bottom Transition Image (Hero Banner Bottom) */}
      {!isSmallScreen && (
        <Box
          component="img"
          src={HeroBannerBottom}
          alt="Hero Banner Bottom"
          sx={{
            position: 'absolute',
            top: '350px', // Matches Adobe XD dimensions
            left: '0px',
            width: '100%', // Makes it responsive
            maxWidth: '1920px', // Restricts to Adobe XD width
            height: 'auto', // Maintains aspect ratio
            maxHeight: '303px', // Prevents overflow beyond XD height
            objectFit: 'cover',
            opacity: 1,
            zIndex: 1, // Above everything else
          }}
        />
      )}

      {/* Extra White Overlay to Fully Hide Video Below */}
      {!isSmallScreen && (
        <Box
          sx={{
            position: 'absolute',
            top: '500px', // Start where the White Shading Rectangle ends
            left: '0px',
            width: '100%',
            height: '100vh', // Extends far down to fully block video
            backgroundColor: 'white',
            zIndex: 1, // Behind text but above video
          }}
        />
      )}

      {/* 6️⃣ Main Heading */}
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '50px', sm: '50px', md: '140px' }, // Adjusted for visibility
          left: '0%', // Aligns the section to the left side of the page
          width: { xs: '100%', sm: '80%', md: '55%' }, // Takes up 55% of the page on larger screens
          height: { xs: 'auto', md: '267px' }, // Maintains proper height
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centers content vertically inside the box
          // alignItems: 'center', // Centers content horizontally inside the box
          textAlign: 'left', // Ensures text is centered
          paddingLeft: { xs: '10px', sm: '20px', md: '25px' },
          paddingRight: { xs: '10px', sm: '20px', md: '25px' },
          opacity: 1,
          zIndex: 5, // Ensure it's above background elements
          // maxWidth: { xs: '100%', sm: '600px', md: '860px' }, // Your XD width
        }}
      >
        
        {/* "Book Sessions" - Larger Text */}
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 500, // SemiBold
            fontSize: { xs: '2.9rem', sm: '4.5rem', md: '5.5rem' }, // Responsive font size
            lineHeight: { xs: '3.5rem', sm: '6rem', md: '100px' }, // Adobe XD line height
            color: '#04003F',
            margin: 0,
            zIndex: 10, // Ensure text is above all other layers
          }}
        >
          Book Sessions
        </Typography>
        
        {/* "with Top Talent at Big Tech" - Smaller Text */}
        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Roboto',
            fontWeight: 500, // SemiBold
            fontSize: { xs: '1.6rem', sm: '2.5rem', md: '3rem' }, // Responsive font size
            lineHeight: 1.2, // Normal line spacing
            color: '#04003F',
            margin: 0,
            zIndex: 10, // Ensure text is visible
          }}
        >
          with Top Talent at Big Tech
        </Typography>
        
        {/* 7️⃣ Subheading Text Below Main Heading */}
        <Typography
          variant="body1"
          sx={{
            marginTop: { xs: '20px', sm: '20px', md: '25px' }, // Adds spacing below main heading
            height: 'auto', // Allow dynamic height
            fontFamily: 'Roboto',
            fontWeight: 400, // Regular
            fontSize: { xs: '0.9rem', sm: '1rem', md: '1rem' }, // Responsive font size
            lineHeight: 2,
            color: '#04003F', // Adobe XD color
            opacity: 1,
            zIndex: 10, // Ensure visibility above background
          }}
        >
          Practice mock interviews with experts from your target company and role. 
          Receive personalized feedback and ace your interviews.
        </Typography>
      </Box>

      

    </Box>

    
  );
};

export default MainHeading;
