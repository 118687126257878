import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Typography, Button, List, ListItem, Box, useTheme, useMediaQuery, CircularProgress } from '@mui/material';

const SessionOption = ({ title, description, price, onBookNowClick }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ border: 1, borderColor: 'divider', p: 1, mb: 1, borderRadius: 1 }}>
      <Typography variant="h6" sx={{ fontSize: isSmallScreen ? '1rem' : '1.1rem' }}>{title}</Typography>
      <Typography sx={{ fontSize: isSmallScreen ? '0.9rem' : '1rem' }}>{description}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
        <Typography sx={{ color: '#04003F', fontSize: isSmallScreen ? '1rem' : '1.15rem', fontWeight: 'bold', flexGrow: 1, textAlign: 'left' }}>
          {price}
        </Typography>
        <Button
          sx={{
            width: 'auto',
            p: 1.3,
            backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)',
            '&:hover': { 
	              backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)' // Darker gradient on hover
	            },
            color: '#04003F',
            borderRadius: 1,
            cursor: 'pointer',
            textTransform: 'none',
            marginRight: 1,
            height: { xs: '40px', sm: '42px' },
            width: { xs: '100px', sm: '120px' },
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
          }}
          onClick={() => onBookNowClick(title, price)}
        >
          Book Now
        </Button>
      </Box>
    </Box>
  );
};

const BookSessionCard = ({ product, uniqueUsername, coachId, role, company }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // State for fetching API data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionContent, setSessionContent] = useState({ features: [], options: [] });

  useEffect(() => {
    const fetchProductOfferings = async () => {
      try {
        setLoading(true); // Start loading
        setError(null); // Reset errors

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/products/product-offerings/?coachId=${coachId}&product=${encodeURIComponent(product)}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch product offerings');
        }

        const data = await response.json();

        // Map API response to the required format
        const features = data.length > 0 ? data[0].features : [];
        const options = data
          .map((offering) => ({
            title: offering.type,
            description: offering.description,
            price: `$${offering.price_per_session}`,
            rawPrice: offering.price_per_session, // Keep a raw numeric value for sorting
          }))
          .sort((a, b) => b.rawPrice - a.rawPrice); // Sort by rawPrice in descending order

        setSessionContent({ features, options });
      } catch (err) {
        console.error('Error fetching product offerings:', err);
        setError('Failed to load session data. Please try again later.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchProductOfferings();
  }, [coachId, product]);

  // Handler for when a booking option is clicked
  const handleBookingOptionClick = (productType) => {
    const queryParams = new URLSearchParams({
      productType: productType,
      product: product,
      role: role || '',
      company: company || '',
    }).toString();

    const stateToPass = { productType, product, coachId, role, company };

    navigate(`/coaches/${uniqueUsername}/booking?${queryParams}`, { state: stateToPass });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const { features, options } = sessionContent;

  return (
    <Card
      sx={{
        position: 'sticky',
        top: 2.5,
        width: '100%',
        p: 2.5,
        borderRadius: 2,
        background: 'background.paper',
        boxShadow: 1,
        ...(isSmallScreen ? { width: '100%', top: 10 } : {}), // Adjust card position and size on small screens
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontSize: isSmallScreen ? '1.1rem' : '1.25rem',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Book a session
      </Typography>
      <List sx={{ listStyle: 'none', p: 1, mb: 2.5 }}>
        {features.map((feature, index) => (
          <ListItem key={index} sx={{ fontSize: isSmallScreen ? '0.9rem' : '1rem' }}>
            {'\u2714'} {feature}
          </ListItem>
        ))}
      </List>
      {options.map((option, index) => (
        <SessionOption
          key={index}
          title={option.title}
          description={option.description}
          price={option.price}
          onBookNowClick={handleBookingOptionClick} // Pass the product type (e.g., session title) to the handler
        />
      ))}
    </Card>
  );
};

export default BookSessionCard;
