import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MainHeading from '../components/MainHeading';
import ProductSection from '../components/ProductSection';
import CompanyLogos from '../components/CompanyLogos';
import Testimonials from "../components/Testimonials";

import { Container, Box, useTheme, useMediaQuery } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleFormSubmit = (productTitle, role, company) => {
      navigate(`/coaches?product=${productTitle}&role=${role}&company=${company}`, {
        state: { productTitle, role, company }
    });
    };

    return (
        <>
          <Container 
            maxWidth="lg" 
            sx={{ 
              minHeight: '120vh', 
              py: 0, 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              position: 'relative', // Ensures absolute positioning works properly
              pb: 25,
              marginTop: '-16px', 
            }}
          >
            {/* Main Heading (55% Width) - Stays in Place */}
            <MainHeading />

            {isSmallScreen ? (
              <>
                {/* 2️⃣ Logos come next on mobile */}
                <Box sx={{ mt: 2 }}>
                  <CompanyLogos />
                </Box>

                {/* 3️⃣ Product section below logos */}
                <Box sx={{ 
                       mt: 2,
                       zIndex: 10, 
                    }}>
                  <ProductSection title="Ace with Mocks" onFormSubmit={handleFormSubmit} />
                </Box>
              </>
            ) : (
              <>
                {/* 2️⃣ Product section positioned beside heading on large screens */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '130px',
                    left: '55%',
                    width: '43%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                  }}
                >
                  <ProductSection title="Ace with Mocks" onFormSubmit={handleFormSubmit} />
                </Box>

                {/* 3️⃣ Logos below everything on large screens */}
                <Box sx={{ mt: { xs: 9, sm: 9, md: 4 } }}>
                  <CompanyLogos />
                </Box>
              </>
            )}

            {/* Testimonials Section */}
            <Box sx={{ mt: { xs: 0, sm: 0, md: -32 }, mb: { xs: -25, sm: -25, md: 0 } }}>
              <Testimonials />
            </Box>
            

          </Container>
        </>
    );
};

export default Home;
