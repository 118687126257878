import React, { useRef, useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Box, Avatar, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import TestimonialStars from '../assets/home_page_assets/testimonial-stars.svg';

import AwsLogo from '../assets/home_page_assets/company-logos/aws-logo@2x.png';
import AmazonLogo from '../assets/home_page_assets/company-logos/amazon-logo-1.png';
import NetflixLogo from '../assets/home_page_assets/company-logos/netflix-symbol.png';
import WalmartLogo from '../assets/home_page_assets/company-logos/walmart-logo-1.png';
import GleanLogo from '../assets/home_page_assets/company-logos/glean-logo-1.png';

const testimonials = [
  {
    text: "****** has a high bar and his in-depth questions made me see gaps in my preparation. Very thankful for the discussion and may setup something again!",
    company: "Netflix",
    // logo: NetflixLogo,
    role: "Group Product Manager",
    image: TestimonialStars,
  },
  {
    text: "Overall, it is a great experience. The simulated setting and questions are similar to the real interview. The expert feedback is invaluable. One of the key things I learn from this session is about answering questions related to ambiguity. He changed the way I have approached this question, and I hope that this will serve me well for my upcoming interview at Meta.",
    company: "Meta",
    // logo: NetflixLogo,
    role: "Senior Software Engineer",
    image: TestimonialStars,
  },
  {
    text: "****** has changed my perspective of answering the questions. He has taught how to elevate thinking while keeping multiple persona's in mind.",
    company: "Amazon",
    // logo: AmazonLogo,
    role: "Principal Product Manager",
    image: TestimonialStars,
  },
  {
    text: "I had an excellent experience in my mock interview! The interviewer was highly professional and supportive. The problem was well-structured and relevant, and the interviewer guided me when I got stuck, making the experience both challenging and insightful. The feedback I received after the interview was incredibly valuable, helping me understand my strengths and areas for improvement. Now, I have a much clearer idea of how these types of interviews work, and I feel more prepared for my next mock session. I’ll definitely be back next week! I highly recommend this interviewer to anyone looking to refine their interview skills. Thank you so much!",
    company: "Stripe",
    // logo: NetflixLogo,
    role: "Software Engineer",
    image: TestimonialStars,
  },
  {
    text: "****** gave me a very thoughtful feedback and advices that is helping me prepare for my interview at Amazon. I would recommend working with him to plan ahead for your interview preparation.",
    company: "Amazon Web Services",
    // logo: AwsLogo,
    role: "Senior Product Manager-Technical",
    image: TestimonialStars,
  },
  {
    text: "Really great feedback on behavioral and case parts of discussion",
    company: "Netflix",
    // logo: NetflixLogo,
    role: "Product Manager",
    image: TestimonialStars,
  },
  {
    text: "Thanks for the in-depth feedback! Super useful session.",
    company: "Amazon Web Services",
    // logo: AwsLogo,
    role: "Senior Product Manager-Technical, ES",
    image: TestimonialStars,
  },
  {
    text: "Great session, reminded me of what to focus on and invest time",
    company: "Walmart",
    // logo: WalmartLogo,
    role: "Group Director, Product",
    image: TestimonialStars,
  },
  {
    text: "Great insights on direction and feedback",
    company: "Glean",
    // logo: GleanLogo,
    role: "Product Manager",
    image: TestimonialStars,
  },
  
];

const Testimonials = () => {
    const scrollRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    
    // Function to check if scrolling is possible
    const checkForScrollPosition = () => {
        if (scrollRef.current) {
        setCanScrollLeft(scrollRef.current.scrollLeft > 0);
        setCanScrollRight(
            scrollRef.current.scrollLeft < scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        );
        console.log("Scroll Position:", {
            left: canScrollLeft,
            right: canScrollRight,
            scrollLeft: scrollRef.current.scrollLeft,
            maxScroll: scrollRef.current.scrollWidth - scrollRef.current.clientWidth
        });
        }
    };

    // Scroll functions
    const scrollLeft = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    // Attach scroll event listener
    useEffect(() => {
        const ref = scrollRef.current;
        if (ref) {
        ref.addEventListener("scroll", checkForScrollPosition);
        checkForScrollPosition(); // Initial check
        }
        return () => {
        if (ref) {
            ref.removeEventListener("scroll", checkForScrollPosition);
        }
        };
    }, []);

    return (
      <Box
        sx={{
          py: { xs: 12, sm: 10, md: 5 },
          backgroundColor: "#fff",
          textAlign: "center",
          position: "relative",
          zIndex: 10,
        }}
      >
  
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            scrollBehavior: "smooth",
            p: 1,
            position: "relative",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
            gap: 2, // Space between testimonials
            paddingLeft: { xs: "2px", md: "50px" },
            paddingRight: { xs: "2px", md: "50px" },
          }}
          ref={scrollRef}
          onScroll={checkForScrollPosition}
        >
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  minWidth: 300,
                  maxWidth: 400,
                  height: 290, // Increased height for spacing
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "flex-start",
                  position: "relative",
                  alignItems: "center",
                  mx: "auto",
                  boxShadow: 0,
                  border: "1px solid #f5973a",
                  borderRadius: 1,
                  p: 2,
                }}
              >
                {/* Top Section with Background Color */}
                <Box
                  sx={{
                    width: "100%",
                    height: "15%", // 18% of card height
                    backgroundColor: "#04003F",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  {/* Centered Stars Image within this Section */}
                  <Box
                    sx={{
                      height: "40%", // Stars take 40% of this section height
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: "30%", // Leaving 30% empty at top
                      width: "100%",
                    }}
                  >
                    <img
                      src={TestimonialStars}
                      alt="5 Star Rating"
                      style={{
                        width: "120px", // Adjust width to fit full stars
                        height: "auto",
                        objectFit: "contain",
                        display: "block",
                      }}
                    />
                  </Box>
                </Box>
                
                <CardContent 
                  sx={{
                    textAlign: "center",
                    mt: "10%", // Adjust margin-top to avoid overlap
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1, // Ensures equal spacing
                  }}
                >
                  {/* Testimonial Text */}
                  <Box 
                    sx={{ 
                      maxHeight: "140px",  // Set a fixed height for the text area
                      overflowY: "auto",   // Enables vertical scrolling
                      scrollbarWidth: "thin", // Firefox support
                      "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar in Chrome/Safari
                      px: 1, // Adds slight padding inside the box
                    }}
                  >
                    <Typography variant="body1" sx={{ color: "#04003F", mb: 2 }}>
                        "{testimonial.text}"
                    </Typography>
                  </Box>

                  {/* Logo & Role Section (Replacing Company Name with Logo) */}
                   <Box
                    sx={{
                        position: "absolute",
                        bottom: "40px", // Ensures role stays at the same height from the bottom
                        left: "50%",
                        transform: "translateX(-50%)", // Center align
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: "#04003F", 
                        fontSize: "16px",
                        textAlign: "center",                        
                      }}
                    >
                        {testimonial.company}
                    </Typography>

                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: "#04003F", 
                        fontSize: "16px",
                        textAlign: "center",                        
                      }}
                    >
                        {testimonial.role}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Box>

        {/* Left Scroll Arrow */}
        {canScrollLeft && (
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: 10,
              transform: "translateY(-50%)",
              zIndex: 10,
              backgroundColor: "rgba(255, 255, 255, 0.9) !important",
              "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9) !important" },
              // color: "#04003F",
              transition: "all 0.2s ease-in-out", // Smooth transition effect
                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9) !important",
                    "& svg": {
                    transform: "scale(1.3)", // Increase arrow size on hover
                    transition: "transform 0.2s ease-in-out",
                    },
                },
            }}
            onClick={scrollLeft}
          >
            <ArrowBackIosNewIcon sx={{ color: "#04003F" }} />
          </IconButton>
        )}

        {/* Right Scroll Arrow */}
        {canScrollRight && (
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              right: 10,
              transform: "translateY(-50%)",
              zIndex: 10,
              backgroundColor: "rgba(255, 255, 255, 0.9) !important",
              // color: "#04003F",
              transition: "all 0.2s ease-in-out", // Smooth transition effect
                "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.9) !important",
                    "& svg": {
                    transform: "scale(1.3)", // Increase arrow size on hover
                    transition: "transform 0.2s ease-in-out",
                    },
                },
            }}
            onClick={scrollRight}
          >
            <ArrowForwardIosIcon sx={{ color: "#04003F" }} />
          </IconButton>
      )}
      </Box>
    );
  };
  
  export default Testimonials;
