import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ question, answer }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Accordion sx={{ 
      boxShadow: 'none', 
      background: 'transparent', 
      mb: 1, 
      "&:before": { display: 'none' },
      // Adjust the border radius and padding for small screens
      ...(isSmallScreen ? { borderRadius: '2px' } : { borderRadius: '4px' })
    }}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        sx={{ 
          backgroundColor: '#fafafa', 
          borderRadius: '4px', 
          ':hover': { backgroundColor: '#f0f0f0' },
        }}
      >
        <Typography sx={{ fontWeight: 'bold', cursor: 'pointer' }}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'transparent', pt: 2, pb: 2.5 }}>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const FAQSection = ({ product, validProducts }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const faqsForProducts = validProducts.reduce((acc, prod) => {
    if (prod === 'Ace with Mocks') {
      acc[prod] = [
        { 
          question: "What can I expect from a mock session?", 
          answer: ( 
            <>
              Mock sessions are 1:1 practice interviews conducted by your selected coach, designed to simulate a 
              real interview experience with someone from your target role and company. Candidates typically 
              book 1-hour sessions, with most using the time for a full-length mock interview, followed by detailed 
              feedback on their responses. Some choose to focus on specific areas, such as the interview process, 
              company culture, or strategies to improve their performance. Each session is designed to help you 
              refine your approach and maximize your chances of interview success.
            </>
          )
        },
        { 
          question: "Can I use my session for general Q&A about the company, its culture, or other job-related topics instead of a mock interview?", 
          answer: ( 
            <>
              If you’d prefer a general discussion, just let your expert know at the start of the session. You can use the time to 
              focus on topics that you believe will best prepare you for your interview.
            </>
          )
        },
        { 
          question: "What if I didn’t get value from my session? Can I get a refund?", 
          answer: ( 
            <>
              Yes, if you’re not satisfied with your session, you can request a refund within 12 hours of the 
              session’s end time. You’ll receive a full refund, minus any payment processing fees charged by our 
              payment provider. Neither the coach nor EastBadge is compensated when a refund is issued. We ask 
              that you submit your refund request promptly, as we need to process coach payments shortly after 
              the session.
            </>
          )
        },
        { 
          question: "Can I cancel my session?", 
          answer: ( 
            <>
              <Typography>
                Yes, you can cancel your session anytime before the scheduled start time. You’ll receive a full refund, 
                minus any payment processing fees charged by our payment provider. EastBadge does not charge a cancellation fee.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                We appreciate advance notice for cancellations so your coach is informed in time and their schedule is 
                respected. Coaches dedicate their time to helping candidates, and early notification allows them to 
                adjust their plans accordingly.
              </Typography>
            </>
          )
        },
        { 
          question: "Can I reschedule or modify my session?", 
          answer: ( 
            <>
              <Typography>
              Yes, you can reschedule or modify your session. While we work on adding this feature to the platform, 
              please email us at <strong>support@eastbadge.com</strong> for any rescheduling or modification requests.
              </Typography>

              <Typography sx={{ mt: 2 }}>
              Reschedules are subject to the coach’s availability, and we appreciate advance notice for any changes. 
              This helps ensure coaches are informed in time and can adjust their schedules accordingly.
              </Typography>
            </>
          )
        },
        { 
          question: "Can I get a first-time discount or a discount for booking multiple sessions?", 
          answer: ( 
            <>
              <Typography>
                No, we do not offer first-time or bulk session discounts. 
              </Typography>

              <Typography sx={{ mt: 2 }}>
                At EastBadge, we focus on keeping our pricing among the lowest compared to similar platforms. 
                We achieve this by optimizing costs across product, marketing, and operations while maintaining 
                minimal margins—often close to zero. As a result, our listed prices are already the best and most 
                competitive we can offer, and we do not provide additional discounts.
              </Typography>

              <Typography sx={{ mt: 2 }}>
                Our goal is to deliver high-quality mock interview experiences at the most accessible rates to help 
                candidates prepare effectively.
              </Typography>
            </>
          ),
        },
        { 
          question: "Can I purchase multiple sessions as a bundle with the same coach to track progress over time?", 
          answer: ( 
            <>
              You can book multiple sessions individually, based on the coach's availability. We don’t offer bundles yet, 
              but if you’d like us to prioritize this feature, contact us at <strong>support@eastbadge.com</strong>.
            </>
          ),
        },
        { 
          question: "What if my question isn’t answered here?", 
          answer: ( 
            <>
              For more answers, visit the {" "}
              <a href="https://www.eastbadge.com/faqs" target="_blank" rel="noopener noreferrer" style={{ color: "#04003F", textDecoration: "underline" }}>
                FAQs
              </a>
              . If you still don’t find what you’re looking for, please reach out to us at 
              <strong>support@eastbadge.com</strong>. We typically respond within a few hours.
            </>
          ),
        },
      ];
    } else if (prod === 'Burnout to Break Free') {
      acc[prod] = [
        { 
          question: "What if my question isn’t answered here?", 
          answer: ( 
            <>
              For more answers, visit the {" "}
              <a href="https://www.eastbadge.com/faqs" target="_blank" rel="noopener noreferrer" style={{ color: "#04003F", textDecoration: "underline" }}>
                FAQs
              </a>
              . If you still don’t find what you’re looking for, please reach out to us at 
              <strong>support@eastbadge.com</strong>. We typically respond within a few hours.
            </>
          ),
        },
      ];
    }
    return acc;
    // ... other products
  }, {});

  // Default FAQs if product not found
  const defaultFaqs = [
    { 
      question: "What if my question isn’t answered here?", 
      answer: ( 
        <>
          For more answers, visit the {" "}
          <a href="https://www.eastbadge.com/faqs" target="_blank" rel="noopener noreferrer" style={{ color: "#04003F", textDecoration: "underline" }}>
            FAQs
          </a>
          . If you still don’t find what you’re looking for, please reach out to us at 
          <strong>support@eastbadge.com</strong>. We typically respond within a few hours.
        </>
      ),
    },
    // ... more default FAQs
  ];

  const faqs = product ? faqsForProducts[product] || defaultFaqs : defaultFaqs;

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>FAQs</Typography>
      {faqs.map((faq, index) => (
        <FAQ key={index} question={faq.question} answer={faq.answer} />
      ))}
    </Box>
  );
};

export default FAQSection;