import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Button, TextField, Typography, Link } from '@mui/material';

import getErrorMessage from '../utils/getErrorMessage';
import { auth } from '../firebase';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await auth.sendPasswordResetEmail(email);
      setMessage('If an account exists with the email provided, a password reset link will be sent.');
    } catch (firebaseError) {
      setError(getErrorMessage(firebaseError));
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      minHeight: '60vh', // Ensures the form is vertically centered in all viewports
      pt: 2,
      '& .MuiTextField-root': { m: 1, width: '100%', borderRadius: '4px' },
      '& .MuiButton-root': { m: 1, width: '100%', borderRadius: '4px', textTransform: 'none' },
      '@media (max-width:600px)': {
        '& .MuiButton-root': { height: '48px' },
        '& .MuiTextField-root': { height: '48px' },
      },
      '@media (min-width:600px)': {
        '& .MuiButton-root': { height: '52px' },
        '& .MuiTextField-root': { height: '52px' },
      }
    }}>
      <Typography component="h1" variant="h5" sx={{
        fontWeight: 'bold',
        mb: 3,
        fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
      }}>
        Forgot Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
        <TextField
          type="email"
          margin="normal"
          required
          fullWidth
          name="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          sx={{ borderRadius: '4px' }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2, mb: 2,
            backgroundImage: 'linear-gradient(180deg, #F5973A 0%, #FFD23E 100%)', 
            '&:hover': { backgroundImage: 'linear-gradient(180deg, #ff870f 0%, #ffd102 100%)'},
            color: '#04003F',
            height: { xs: '48px', sm: '52px' },
            fontSize: '1.0rem'
          }}
        >
          Send Reset Link
        </Button>
        {message && <Typography sx={{ mt: 2, textAlign: 'center' }}>{message}</Typography>}
        {error && <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>{error}</Typography>}
      </Box>
      <Button 
        onClick={() => navigate('/signin', { state: { from: location.state?.from } })} // Preserve state when going back to SignIn
        sx={{
          mt: 2,
          bgcolor: 'transparent',
          color: '#04003F',
          '&:hover': { bgcolor: 'transparent', textDecoration: 'underline' },
          fontSize: '1.0rem'
      }}>
        Sign In Page
      </Button>
    </Container>
  );
};

export default ForgotPassword;
