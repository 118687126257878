// src/pages/BecomeAnExpert.jsx

import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';    

const BecomeAnExpert = () => {
  return (
    <Container 
        maxWidth="md" 
        sx={{ 
            flex: 1, // Ensures it takes up remaining space between header & footer
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '50vh', // Ensures enough height for centering
        }}
    >
        <Box sx={{ textAlign: 'center' }}>
            <Typography
                variant="h4"
                component="h1"
                sx={{
                    fontFamily: 'Roboto',
                    fontWeight: 400, // Regular
                    fontSize: { xs: '1rem', sm: '1rem', md: '1rem' }, // Responsive font size
                    lineHeight: 2,
                    color: '#04003F', // Adobe XD color
                    maxWidth: '90%', // Ensures responsiveness
                    mx: 'auto' // Centers the text horizontally
                }}
            >
                Please email us at{' '}
                <Typography component="span" sx={{ fontWeight: 600, display: 'inline', fontSize: { xs: '1rem', sm: '1rem', md: '1rem' } }}>
                  support@eastbadge.com
                </Typography>{' '}
                with the link to your LinkedIn {' '}
                <LinkedInIcon sx={{ verticalAlign: 'middle', fontSize: '1.5rem', color: '#0072b1' }} /> profile to learn more. 
                Our expert onboarding functionality is under development and is expected to go live in H1 2025.
            </Typography>
        </Box>
    </Container>
  );
};  
    
    

export default BecomeAnExpert;
