// src/pages/ContactUs.jsx

import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const ContactUs = () => {
  return (
    <Container 
        maxWidth="md" 
        sx={{ 
            flex: 1, // Ensures it takes up remaining space between header & footer
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '50vh', // Ensures enough height for centering
        }}
    >
        <Box sx={{ textAlign: 'center' }}>
            <Typography
                variant="h4"
                component="h1"
                sx={{
                    color: '#424242', 
                    fontSize: '1.2rem',
                    maxWidth: '90%', // Ensures responsiveness
                    mx: 'auto' // Centers the text horizontally
                }}
            >
                For questions, requests, or feedback, contact us at{' '}
                <Typography component="span" sx={{ fontWeight: 'bold', display: 'inline', color: '#424242', fontSize: '1.2rem' }}>
                    support@eastbadge.com
                </Typography>{' '}
                — we typically respond within hours.
            </Typography>
        </Box>
    </Container>
  );
};

export default ContactUs;
